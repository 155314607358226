import type { AxiosError } from "axios"
import axios from "axios"
import axiosRetry, {
    exponentialDelay,
    isNetworkError,
    isRetryableError,
} from "axios-retry"
import { debounce } from "underscore"
import * as Sentry from "@sentry/browser"
import qs from "qs"
import { initAuth } from "./auth"
import amplitude from "amplitude-js"

const api = axios.create({
    baseURL: "/api",
    timeout: 30_000,
    paramsSerializer: {
        serialize: (params) => qs.stringify(params, { encode: false }),
    },
})
axiosRetry(api, {
    retries: 3,
    retryDelay: exponentialDelay,
    retryCondition: (err) => isRetryableError(err) || isNetworkError(err), // retry all HTTP methods
})

const handleUnauthorized = debounce(
    () => {
        initAuth(api)
    },
    3000,
    true,
)

api.interceptors.request.use((config) => {
    config.headers["X-Amplitude-Session-ID"] = amplitude
        .getInstance()
        .getSessionId()
    config.headers["X-Amplitude-Device-ID"] = amplitude
        .getInstance()
        .getDeviceId()
    return config
})

api.interceptors.response.use(
    (resp) => resp,
    (error: AxiosError) => {
        Sentry.setContext("axiosResponse", {
            status: error.response?.status,
            data: error.response?.data,
            dataRaw: JSON.stringify(error.response?.data),
            headers: error.response?.headers,
        })
        if (error.response?.status === 401) {
            handleUnauthorized()
        } else {
            Sentry.captureException(error)
        }
        throw error
    },
)

export default api
