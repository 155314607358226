<script setup lang="ts">
import type { ButtonHTMLAttributes } from "vue"
import trackEvent from "~/utils/track-event"

interface PitchlaneButtonProps extends /* @vue-ignore */ ButtonHTMLAttributes {
    eventName: string
    eventProps?: Record<string, any>
    isLoading?: boolean
}

const props = defineProps<PitchlaneButtonProps>()
const emit = defineEmits<{
    (event: "click", e?: MouseEvent): void
    (event: any): any
}>()

function onClick(event: MouseEvent) {
    trackEvent(props.eventName, props.eventProps)
    emit("click", event)
}
</script>
<template>
    <button
        v-bind="$attrs"
        @click="onClick"
    >
        <ph-spinner-thin
            v-if="isLoading"
            class="w-6 h-6 text-white animate-spin"
        />
        <slot />
    </button>
</template>
