import {
    CheckoutEventsData,
    Environments,
    Paddle,
    initializePaddle,
} from "@paddle/paddle-js"
import { ref } from "vue"
import { isProd } from "~/utils/environment"
import { createNanoEvents } from "nanoevents"
import trackEvent from "./track-event"
import { currentUser } from "./auth"

const PADDLE_CREDENTIALS = {
    sandbox: {
        environment: "sandbox",
        token: "test_e3bb9a9678cf7dd527523d38986",
    },
    live: {
        environment: "production",
        token: "live_a77f65778ab858c9ac696ac90de",
    },
}

export const paddleRef = ref<Paddle>()
export const paddleEvents = createNanoEvents<{
    [event: string]: (data: CheckoutEventsData) => void
}>()

export async function initPaddle() {
    const credentials = isProd()
        ? PADDLE_CREDENTIALS.live
        : PADDLE_CREDENTIALS.sandbox

    const paddle = await initializePaddle({
        token: credentials.token,
        environment: credentials.environment as Environments,
        eventCallback: (event) => {
            paddleEvents.emit(event.name!, event.data!)
            trackEvent(`Paddle ${event.name}`, event.data)
        },
        checkout: {
            settings: {
                theme: "light",
                locale: "en", // since everything else is in english, checkout should be in english
            },
        },
        pwCustomer: {
            email: currentUser.value?.email,
        },
    })

    paddleRef.value = paddle
}

export async function getPaddle(): Promise<Paddle> {
    if (!paddleRef.value) {
        await initPaddle()
    }
    return paddleRef.value!
}
