import { reactive, watch } from "vue"
import api from "./api"
import { currentToken } from "./auth"
import { useIntervalFn } from "@vueuse/core"
import { SubscriptionEntitlements, type QuotaData } from "@pitchlane/shared"

export interface Quotas {
    loaded: boolean
    videosLeft: number
    scheduledCancellation?: string
    hasActiveSubscription: boolean
    hasAnySubscription: boolean
    initialCreditsIssued: boolean
    usedVideosAllTime: number
    totalCredits: number
    renewal?: Date
    entitlements?: SubscriptionEntitlements
    flags?: Record<string, boolean>
    isInTrial: boolean
    paddleCustomerId?: string
    monthlyVideos?: number

    renderedVideosQuota: QuotaData
    linkedInPagesQuota: QuotaData
}

export const quota = reactive<Quotas>({
    loaded: false,
    videosLeft: 0,
    totalCredits: 0,
    hasActiveSubscription: false,
    hasAnySubscription: false,
    usedVideosAllTime: 0,
    initialCreditsIssued: false,
    isInTrial: false,
    renderedVideosQuota: {
        quota: "RENDERED_VIDEOS",
        used: 0,
        total: 0,
        remaining: 0,
    },
    linkedInPagesQuota: {
        quota: "LINKEDIN_PAGES",
        used: 0,
        total: 0,
        remaining: 0,
    },
})

export async function refreshQuota() {
    const response = await api.get("/quota")
    Object.assign(quota, response.data)
    quota.renewal = response.data.renewal
        ? new Date(response.data.renewal)
        : undefined
    quota.loaded = true
}

watch(currentToken, () => refreshQuota())

// refresh quota every minute
useIntervalFn(() => {
    refreshQuota()
}, 60_000)
