export function initMedian() {
  const e = window.Median = window.Median || [];
  if (!e._initialized) {
    if (e._snippet_loaded) {
      // eslint-disable-next-line no-console
      console.warn("Median Snippet loaded twice.");
    } else {
      e._snippet_loaded = true;
      e._snippet_version = 3;
      e.methods = ["init", "identify", "endSession", "on"];
      e.factory = function (n) {
        return function () {
          // eslint-disable-next-line
          const t = Array.from(arguments);
          e.push([n, t]);
        };
      };
      for (let n = 0; n < e.methods.length; n++) {
        const t = e.methods[n];
        e[t] = e.factory(t);
      }
      const i = document.createElement("script");
      i.type = "text/javascript";
      i.async = true;
      i.src = "https://js.hellomedian.com/v1/mdn-cobrowse-screenshare.js";
      const a = document.getElementsByTagName("script")[0];
      if (a && a.parentNode) {
        a.parentNode.insertBefore(i, a);
      }
    }
  }

  if (window.Median && typeof window.Median.init === 'function') {
    window.Median.init("97346409-2e86-4bc6-9623-09e1bf4920c6");
  }
}
