import { ref, onMounted, onUnmounted } from "vue"

export default function useIsMobile() {
    const isMobile = ref<boolean>(false)

    const checkIsMobile = () => {
        isMobile.value = window.innerWidth < 768
    }

    onMounted(() => {
        checkIsMobile()
        window.addEventListener("resize", checkIsMobile)
    })

    onUnmounted(() => {
        window.removeEventListener("resize", checkIsMobile)
    })

    return { isMobile }
}
