import amplitude from "amplitude-js"
import { watch } from "vue-demi"
import type { RouteLocationNormalizedLoaded } from "vue-router"
import { useRoute } from "vue-router"
import { currentUser } from "./auth"
import { isProd } from "./environment"
import Gleap from "gleap"

const AMPLITUDE_API_KEY = isProd()
    ? "f9f811d37c3d0139602009920a48d32d"
    : "890e00660bebef9fdf2c98291569f46a"

const instance = amplitude.getInstance()
instance.init(AMPLITUDE_API_KEY)

instance.getSessionId()

watch(currentUser, (user) => instance.setUserId(user?.sub ?? null))

let route: RouteLocationNormalizedLoaded | undefined

export default function trackEvent(
    name: string,
    customData?: Record<string, any>,
) {
    const baseData: Record<string, any> = {}

    // `useRoute` is based on injections, so we have to "cache" its value here
    if (!route) {
        route = useRoute()
    }

    if (route?.params.campaign) {
        baseData["Campaign ID"] = route.params.campaign
    }
    if (route?.query.video) {
        baseData["Video ID"] = route.query.video
    }

    const data = { ...baseData, ...customData }

    try {
        window.analytics?.track(name, data)
    } catch (error) {
        // eslint-disable-next-line no-console
        console.warn(
            "An error occured while trying to submit an analytics event to Segment:",
            error,
        )
    }

    try {
        instance.logEvent(name, data)
    } catch (error) {
        // eslint-disable-next-line no-console
        console.warn(
            "An error occured while trying to submit an analytics event to Amplitude:",
            error,
        )
    }

    try {
        Gleap.trackEvent(name, data)
    } catch (error) {
        // eslint-disable-next-line no-console
        console.warn(
            "An error occured while trying to submit an event to Gleap:",
            error,
        )
    }

    // check iubenda consent before sending events to pixels
    const { purposes = {} } = window._iub?.cs?.api?.getPreferences() ?? {}

    if (window.fbq && purposes?.["5"]) {
        try {
            window.fbq("trackCustom", name, data)
        } catch (error) {
            // eslint-disable-next-line no-console
            console.warn(
                "An error occured while trying to submit an event to Facebook Pixel:",
                error,
            )
        }
    }

    if (window.gtag && purposes?.["5"]) {
        try {
            window.gtag("event", name, data)
        } catch (error) {
            // eslint-disable-next-line no-console
            console.warn(
                "An error occured while trying to submit an event to gtag:",
                error,
            )
        }
    }
}
