import type { Auth0Client, User } from "@auth0/auth0-spa-js"
import { createAuth0Client } from "@auth0/auth0-spa-js"
import { ref } from "vue"
import type { AxiosInstance } from "axios"
import { useRouter } from "vue-router"
import { isProd } from "~/utils/environment"

let auth0: Auth0Client
export const currentUser = ref<User>()
export const currentToken = ref<string>()
export const currentUserData = ref<Record<string, any>>()

import identify from "~/utils/identify"

// Using two separate Auth0 applications for production and development allows
// us to remove `localhost` from the allowed origins of the production app which
// makes it possible to skip the "Allow Pitchlane access to Pitchlane" prompt
const CLIENT_ID_PROD = "U0HsQGvAhdArgLa4C0upDWzqjoPJQIZr"
const CLIENT_ID_STAGING = "sjTnK0jFooYV6jlqr6UuXKvyvlxJ3sWd"

async function handleLoginRequired() {
    console.info("Not logged in! Redirecting to login page...")
    const query = new URLSearchParams(location.search)
    query.delete("redirectTo")
    const redirectUri = `${location.origin}?redirectTo=${encodeURIComponent(
        `${location.pathname}?${query.toString()}`,
    )}`
    await auth0.loginWithRedirect({
        authorizationParams: {
            screen_hint: location.pathname.includes("sign-up")
                ? "signup"
                : "login",
            redirect_uri: redirectUri,
        },
    })
}

const router = useRouter()

export async function refreshCurrentUserData(api: AxiosInstance) {
    const result = await api.get("/me")
    currentUserData.value = result.data
}

export async function initAuth(api: AxiosInstance) {
    if (!auth0) {
        auth0 = await createAuth0Client({
            domain: isProd()
                ? "pitchlane.eu.auth0.com"
                : "pitchlane-staging.eu.auth0.com",
            clientId: isProd() ? CLIENT_ID_PROD : CLIENT_ID_STAGING,
            authorizationParams: {
                audience: isProd()
                    ? "https://app.pitchlane.io/api"
                    : "https://api.staging.pitchlane.io",
                scope: "openid email profile offline_access dashboard",
            },
            cacheLocation: "localstorage",
            useRefreshTokens: true,
            useRefreshTokensFallback: true,
            leeway: 120,
        })
    }

    const params = new URLSearchParams(location.search)
    if (params.has("code") && params.has("state")) {
        try {
            await auth0.handleRedirectCallback()
        } catch (error) {
            console.error("auth0.handleRedirectCallback error", error)
            // return await handleLoginRequired()
            throw error
        }

        const user = await auth0.getUser()
        if (user) {
            currentToken.value = await auth0.getTokenSilently()
            api.defaults.headers.common.Authorization =
                `Bearer ${currentToken.value}` as string

            const result = await api.get("/me")
            currentUserData.value = result.data

            const { postSignUpCompleted } = result.data?.user_metadata || {}

            const redirectTo = postSignUpCompleted
                ? params.get("redirectTo") ?? "/"
                : `/onboarding/register?redirectTo=${params.get("redirectTo")}`

            if (router) {
                router.replace(redirectTo)
            } else {
                window.location.href = redirectTo
            }
        } else {
            throw new Error(`User not found after redirect callback!`)
        }
    }

    const user = await auth0.getUser()

    if (user) {
        console.info("Logged in!")
        try {
            currentToken.value = await auth0.getTokenSilently()
            api.defaults.headers.common.Authorization =
                `Bearer ${currentToken.value}` as string
        } catch (err) {
            // eslint-disable-next-line no-console
            console.warn("Failed to get token silently", err)
            return await handleLoginRequired()
        }
        currentUser.value = user
        currentUserData.value = undefined

        await identify()
    } else {
        await handleLoginRequired()
    }
}

export async function logout() {
    auth0.logout({ logoutParams: { returnTo: "https://pitchlane.com" } })
    window.Beacon?.("logout")
}
